import React from "react"
import { Link } from "gatsby"

import Nav from "../components/nav"
import Logo from "../images/logo.svg"

const Header = () => (
    <header>
        <div className="header-inner">
            <div className="header-logo">
                <a href="/">
                    <Logo />
                </a>
            </div>
            <Nav />
        </div>

    </header>
)

export default Header