import React, { useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

import Burger from "../images/burger.svg"
import Cross from "../images/close.svg"

const Nav = () => {
    const data = useStaticQuery(graphql`
        query NavQuery {
            site {
                siteMetadata {
                  menuLinks {
                    link
                    name
                    subMenu {
                      link
                      name
                    }
                  }
                }
              }
            }
    `)

    const menuLinks = data.site.siteMetadata.menuLinks

    const [isActive, setActive] = useState("false")

    const handleToggle = () => {
        setActive(!isActive)
    }

    const [isOpen, setOpen] = useState("false")

    const handleOpen = () => {
        setOpen(!isOpen)
    }
    

    return (
        <nav>
            <a onClick={handleToggle} class="menu-link hide-for-medium hide-for-large">{isActive ? (<Burger />) : (<Cross />)}</a>
            <ul id="main-nav" className={isActive ? null : "open"}>
                {menuLinks.map((link) => (
                    <li onClick={handleOpen} key={link.name} className={link.subMenu && link.subMenu.length > 0 ? isOpen ? "has-children" : "has-children child-open" : null }>
                        <Link to={link.link} activeClassName="active" >
                            {link.name}
                        </Link>
                        {link.subMenu && link.subMenu.length > 0 ? (
                            <ul aria-label="submenu">
                                {link.subMenu.map((subLink) => (
                                    <li key={subLink.name}>
                                        <Link to={subLink.link} activeClassName="active">{subLink.name}</Link>
                                    </li>
                                ))}
                            </ul>
                        ) : null }
                    </li>
                ))}
            </ul>
        </nav>
    )
}

export default Nav
