import React from "react"



const Footer = () => {
    const thisYear = new Date().getFullYear();
    
    return (
    <footer>
        <div className="footer-inner">
            <div className="footer-left">
                <h3>Contact us</h3>
                <p><a href="mailto:info@blackice.io">info@blackice.io</a></p>
                <p><a href="tel:+618 6377 7690">+618 6377 7690</a></p>
                <p>Suite 14A, Level 2, 435 Roberts Road, Subiaco 6008</p>
                <p>PO Box 307, Inglewood WA 6932</p>
            </div>
            <div className="footer-right">
                <p>&copy; Copyright {thisYear} Black Ice</p>
                <p>Black Ice uses a quality management system <a href="https://res.cloudinary.com/black-ice/image/upload/v1718142271/Black_Ice_ISO9001_3_2015_Certificate_ID_15061_axs8dp.pdf">certified to ISO9001</a></p>
                <p>website design by <a href="https://pixelboom.nz">Pixel Boom</a></p>
            </div>
        </div>
    </footer>
)}

export default Footer